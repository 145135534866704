import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MUIFormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const FormGroup = ({ classes, children, className, ...rest }) => (
  <MUIFormGroup className={cn(classes.root, className)} {...rest}>
    {children}
  </MUIFormGroup>
);

FormGroup.propTypes = {
  classes: PropTypes.shape().isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

FormGroup.defaultProps = {
  children: null,
  className: '',
};

export default withStyles(styles)(FormGroup);
